import React from 'react';
import { FaUserFriends } from 'react-icons/fa';

const SubscriberCount: React.FC<{ count: number; text: string; onClick: () => void }> = ({ count, text, onClick }) => {
  return (
    <div style={{
      marginTop: '5px',
      float: 'right',
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
      color: '#2a2b21'
    }}
    onClick={onClick}
    >
      <FaUserFriends />
      <span>{count} {count === 1 ? text : `${text}s`}</span>
    </div>
  );
};

export default SubscriberCount;
